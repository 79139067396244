<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-05 16:32:13
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-29 22:24:14
 * @model: 首页
-->
<template>
  <div>
    <div class="wrap">
      <!-- 轮播图 -->
      <!-- <van-swipe :autoplay="3000" touchable>
        <van-swipe-item v-for="(image, index) in banner_list" :key="index">
          <img v-lazy="image.b_img" @click="go(image.b_jumpurl)" />
        </van-swipe-item>
      </van-swipe> -->

      <div class="swiper-container" style="width:100%;">
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(image, index) in banner_list" :key="index"  @click="go(image.b_jumpurl)">
               <img :src="image.b_img" class="home_banner"/>
            </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
    </div>

    </div>
    <!-- 内容部分 -->
    <div class="home_wrap">
      <template v-for="(item,index) in module_list" >
        <!-- 左图右文 -->
        <div class="left_wrap" v-if="item.m_art_left_or_right == 1" :key="index">
          <img v-lazy="item.m_art_img" class="item_img animate__animated animate__fadeInLeft" alt />
          <div class="item_con animate__animated animate__slideInRight">
            <div class="title_wrap">
                <img :src="item.m_title_img" v-if="item.m_title_img" alt="">
                <h2>{{ item.m_title }}</h2>
                <h3>{{ item.m_title_en }}</h3>
            </div>
            <p v-html="item.m_art_desc"> </p>
            <a :href="item.m_art_jump_url">
              了解更多
              <img src="@/assets/icon/right_icon.png" alt />
            </a>
          </div>
        </div>

        <div class="right_wrap" v-else :key="index">
          <div class="item_con animate__animated animate__slideInLeft">
            <div class="title_wrap">
                <img :src="item.m_title_img" v-if="item.m_title_img" alt="">
                <h2>{{ item.m_title }}</h2>
                <h3>{{ item.m_title_en }}</h3>
            </div>
            <p v-html="item.m_art_desc"> </p>
            <a :href="item.m_art_jump_url">
              了解更多
              <img src="@/assets/icon/right_icon.png" alt />
            </a>
          </div>
          <img v-lazy="item.m_art_img" class="item_img animate__animated animate__fadeInRight" alt />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
import { Swipe, SwipeItem } from "vant";

import Swiper from 'swiper';  
import 'swiper/dist/css/swiper.css'
import '@/my-theme/animate.css'


Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(Lazyload);
import { postAction } from "@/api/manage";
export default {
  name: "App112",
  components: {},
  data() {
    return {
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg"
      ],
      banner_list: [],
      module_list: []
    };
  },
  created() {
    this.$store.commit("SET_LOADING", true);
    if(localStorage.banner_list){
      this.banner_list = JSON.parse(localStorage.banner_list);
    }
    this.getCon();
  },
  computed: {

  },
  methods: {
    go(url) {
      if (url) {
        location.href = url;
      }
    },
    /**
     * @name: 获取页面内容
     * @msg:
     * @param {*}
     * @return {*}
     */
    getCon() {
      postAction("/indexmodule/index", {}).then(res => {
        this.$store.commit("SET_LOADING", false);
        if (res.code == 0) {
          // console.log(res);
          this.banner_list = res.data.banner_list;
          this.module_list = res.data.module_list;
          localStorage.banner_list = JSON.stringify(this.banner_list);
          let loopSets = {
            loop: true, // 循环模式选项
            autoplay:{
              disableOnInteraction:false,
              delay:10000,
              autoplay:true
            },
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
              clickable:true
            }
          } 
          if(this.banner_list.length <= 1) {
            loopSets = {
              // 如果需要分页器
              pagination: {
                el: '.swiper-pagination',
                clickable:true
              }
            }
          }
          setTimeout(()=>{
            new Swiper('.swiper-container', loopSets);
          },500)
          
        }
      });
    }
  }
};
</script>
<style lang="less" scoped>
.home_wrap {
  padding: 1.20rem 0;
  width: 14rem;
  max-width: 1400px;
  margin: auto;
  .right_wrap {
    display: flex;
    justify-content: space-between;
    .item_img {
      width: 7rem;
      object-fit: cover;
      height: 7rem;
    }
    .item_con {
      padding: .80rem .67rem 0 0px;
      h2 {
        font-size: .52rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #444855;
        margin-bottom: .12rem;
        line-height: .56rem;
      }
      h3{
        height: .26rem;
        font-size: .26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3B3B3B;
        line-height: .25rem;
      }
      p {
        font-size: .16rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3b3b3b;
        line-height: .40rem;
        margin-bottom: .54rem;
      }
      a {
        width: 1.64rem;
        height: .46rem;
        background: #ffffff;
        border: 1px solid #00c99d;
        border-radius: .23rem;
        display: block;
        text-align: center;
        font-size: .14rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #00c99d;
        line-height: .46rem;
        img {
          display: inline-block;
          width: 0.08rem;
          margin-left: .10rem;
        }
        &:hover {
          opacity: 0.55;
        }
      }
    }
  }
  .left_wrap {
    display: flex;
    .item_img {
      width: 7rem;
      object-fit: cover;
      height: 7rem;
    }
    .item_con {
      padding: .80rem 0px 0 .67rem;
      h2 {
        font-size: .52rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #444855;
        margin-bottom: .12rem;
        line-height: .56rem;
      }
      h3{
        height: .26rem;
        font-size: .26rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: #3B3B3B;
        line-height: .25rem;
      }
      p {
        font-size: .16rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3b3b3b;
        line-height: .40rem;
        margin-bottom: .54rem;
      }
      a {
        width: 1.64rem;
        height: .46rem;
        background: #ffffff;
        border: 1px solid #00c99d;
        border-radius: .23rem;
        display: block;
        text-align: center;
        font-size: .14rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #00c99d;
        line-height: .46rem;
        img {
          display: inline-block;
          width: .08rem;
          margin-left: .10rem;
        }
        &:hover {
          opacity: 0.55;
        }
      }
    }
  }
}
.wrap{
  height: 6.30rem;
  width: 100%;
}





// @media screen and (max-width: 1300px) {
//   .wrap{
//     height: 560px;
//     .home_banner{
//       height: 560px;
//     }
//   }
//   .home_wrap{
//     padding: 80px 50px ;
//     .right_wrap {
//       .item_img {
//         width: 50%;
//         object-fit: cover;
//         height: 500px;
//       }
//       .item_con {
//         padding: 40px 37px 0 0px;
//         h2 {
//           font-size: 42px;
//           margin-bottom: 12px;
//           line-height: 42px;
//         }
//         h3{
//           height: 26px;
//           font-size: 26px;
//           line-height: 25px;
//         }
//         p {
//           font-size: 14px;
//           line-height: 32px;
//           margin-bottom: 54px;
//         }
//       }
//     }

//     .left_wrap {
//       display: flex;
//       .item_img {
//         width: 50%;
//         object-fit: cover;
//         height: 500px;
//       }
//       .item_con {
//         padding: 40px 0px 0 37px;
//         h2 {
//           font-size: 42px;
//           margin-bottom: 12px;
//           line-height: 42px;
//         }
//         h3{
//           height: 26px;
//           font-size: 26px;
//           color: #3B3B3B;
//           line-height: 25px;
//         }
//         p {
//           font-size: 14px;
//           line-height: 32px;
//           margin-bottom: 54px;
//         }
//       }
//     }
//   }

//   .title_wrap{
//     margin-bottom: 48px;
//     img{
//       float: left;
//       width: 82px;
//       height: 82px;
//       object-fit: cover;
//       margin-right: 18px;
//     }
//   }
// }

// @media screen and (max-width: 1200px) {
//   .wrap{
//     height: 460px;
//     .home_banner{
//       height: 460px;
//     }
//   }

//   .home_wrap{
//     padding: 80px 50px ;
//     .right_wrap {
//       .item_img {
//         width: 50%;
//         object-fit: cover;
//         height: 500px;
//       }
//       .item_con {
//         padding: 40px 37px 0 0px;
//         h2 {
//           font-size: 36px;
//           margin-bottom: 12px;
//           line-height: 36px;
//         }
//         h3{
//           height: 22px;
//           font-size: 22px;
//           color: #3B3B3B;
//           line-height: 25px;
//         }
//         p {
//           font-size: 12px;
//           line-height: 26px;
//           margin-bottom: 46px;
//         }
//       }
//     }

//     .left_wrap {
//       display: flex;
//       .item_img {
//         width: 50%;
//         object-fit: cover;
//         height: 500px;
//       }
//       .item_con {
//         padding: 40px 0px 0 37px;
//         h2 {
//           font-size: 36px;
//           margin-bottom: 12px;
//           line-height: 36px;
//         }
//         h3{
//           height: 22px;
//           font-size: 22px;
//           color: #3B3B3B;
//           line-height: 25px;
//         }
//         p {
//           font-size: 12px;
//           line-height: 26px;
//           margin-bottom: 46px;
//         }
//       }
//     }
//   }

//   .title_wrap{
//     margin-bottom: 48px;
//     img{
//       float: left;
//       width: 82px;
//       height: 82px;
//       object-fit: cover;
//       margin-right: 18px;
//     }
//   }



// }
</style>
<style lang='less'>
.home_desc {
  p {
    margin: .24rem 0;
    font-size: 0.8125rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3b3b3b;
    line-height: 1.5625rem;
    text-align: left;
  }
}
.van-swipe-item {
  img {
    display: block;
    width: 100%;
    height: 6.30rem;
    object-fit: cover;
   
  }
}
.home_banner{
  display: block;
  width: 100%;
  height: 6.30rem;
  object-fit: cover;
  cursor: pointer;
  &:hover{
    opacity: 0.75;
  }
}
.van-swipe__indicators {
  .van-swipe__indicator {
    width: 35px;
    border-radius: 0px;
    height: 6px;
    overflow: hidden;
  }
  .van-swipe__indicator--active {
    background: #00c99d;
  }
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet{
  width: 35px;
  border-radius: 0px;
  height: 6px;
  overflow: hidden;
}
.swiper-pagination-bullet-active{
  background: #00c99d;
}



.title_wrap{
  overflow: hidden;
  margin-bottom: .72rem;
  img{
    float: left;
    width: .96rem;
    height: .96rem;
    object-fit: cover;
    margin-right: .24rem;
  }
}
</style>


