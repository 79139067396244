<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-06 21:12:40
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-23 22:40:24
 * @model: 品牌风采
-->


<template>
  <div>
    <div class="wrap">
      <!--  视频介绍 -->
      <div class="top_wrap" v-if="data1.m_status == 1">
          <video id="video" x5-playsinline="true" webkit-playsinline="true" playsinline="true" @click="stopPlay" v-if="PlayURL" loop :src="PlayURL" preload="" :poster="data1.m_video_cover"></video>
          <img v-if="!isPlay" src="@/assets/brand/video_icon.png" @click="videoPlay"  alt="">
      </div>

      <!-- 富文本编辑框 -->
      <div class="con_wrap" v-if="data2.m_status == 1">
            <div class="left_wrap" v-html="data2.m_desc"> </div>
            <!-- {{data2}} -->
            <div class="right_wrap">
                <img :src="data2.m_desc_img" alt="">
            </div>
      </div>
      <!-- 品牌风采 -->
      <div class="show_img" v-show="data3.m_status == 1">
            <img class="top" src="@/assets/brand/top.png" alt="">
            <div class="show_img_list">
                <!-- <van-image class="item_img" :src="item" v-for='(item,index) in data3.img_list' :key='index' /> -->

                <viewer :images="data3.img_list">
                    <img
                        v-for='(src,index) in data3.img_list'
                        :src="src"
                        :key="index"
                        class="item_img"
                    />
                </viewer>

                <!-- <img v-for='(src,index) in data3.img_list' :src="src" :key="index" class="item_img" /> -->
            </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Image as VanImage } from 'vant';

Vue.use(VanImage);
import { postAction } from '@/api/manage'
import imgs from '@/assets/brand/6.png';
// import { ImagePreview } from 'vant';

export default {
  name: 'about',
  components: {
  },
  data(){
    return {
        imgs,
        isPlay:false,
        isDown:false,
        data1:{},
        data2:{},
        data3:{},
        PlayURL:null
    }
  },
  computed: {
   
  },
  created() {
      this.$store.commit('SET_LOADING',true);
      this.getCon();

  },
  methods:{
     stopPlay() {
        var video = document.getElementById("video");
        video.pause();
        this.isPlay = false;
     },
     videoPlay() {
         var video = document.getElementById("video");
         //开始播放
         if (video.paused == true) {
            video.play();
            this.isPlay = true;
        } else {
            video.pause();
            this.isPlay = false;
        }
     },
     /**
      * @name: 获取页面内容
      * @msg: 
      * @param {*}
      * @return {*}
      */
     getCon() {
         postAction('/storymodule/index',{}).then((res)=>{
            this.$store.commit('SET_LOADING',false);
            if(res.code == 0){
                console.log(res);
                this.data1 = res.data.module_list.data1;
                this.data2 = res.data.module_list.data2;
                this.data3 = res.data.module_list.data3;
                if(this.data1.play_data.PlayInfo.length > 0){
                    this.PlayURL = this.data1.play_data.PlayInfo[0].PlayURL;
                }  
            }
        })
     }
  }
}
</script>
<style lang="less" scoped>
.wrap{
   padding: 0rem 0;
   background: #fff;
   .top_wrap{
       position: relative;
       height: 7.90rem;
       video{
           display: block;
           width: 100%;
           height: 7.90rem;
           object-fit: cover;
       }
       img{
           width: 20%;
           position: absolute;
           top: 0;left: 0;
           bottom: 0;right: 0;
           margin: auto;
       }
   }
}

.con_wrap{
    padding: 1.65rem 0rem .12rem;
    display: flex;
    max-width: 1400px;
    min-width: 800px;
    width:14rem;
    margin: auto;
    .left_wrap{
        width: 100%;
        margin-right: .72rem;
        div{
            margin-bottom: .64rem;
        }
        h2{
            height: .29rem;
            font-size: .30rem;
            font-family: PingFang SC;
            font-weight: 500;
            color: #333333;
            line-height: .24rem;
            margin-bottom: .18rem;
        }
        p{
            font-size: .14rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: .24rem;
        }
    }
    .right_wrap{
        width: 7.38rem;
        min-width: 7.38rem;
        img{
            display: block;
            width: 100%;
        }
    }
}
    
.show_img{
    // max-width: 1400px;
    // min-width: 1200px;
    padding: .70rem 0rem 2.00rem;
    .top{
        display: block;
        margin: auto;
        width: 5.66rem;
    }
    .show_img_list{
        overflow: hidden;
        margin:.64rem auto 0;
        width: 14.04rem;
       .item_img{
           float: left;
           width: 4.52rem;
           height: 3.72rem;
        //    object-fit: cover;
           margin-bottom: .20rem;
           &:hover{
               opacity: .55;
           }
           &:active{
               opacity: .15;
           }
           &:nth-child(3n+2){
               margin: 0 .24rem .20rem;
           }
       }
    }
}


// @media screen and (max-width: 1300px) {
//     .wrap{
//         .top_wrap{
//             height: 650px;
//             video{
//                 height: 650px;
//             }
//             img{
//                 width: 20%;
//             }
//         }
//     }
//     .con_wrap{
//         padding: 125px 10px 12px;
//         display: flex;
//         width: 100%;
//         min-width: 1000px;
//         margin: auto;
//         .left_wrap{
//             width: 100%;
//             margin-right: 48px;
//             div{
//                 margin-bottom: 32px;
//             }
//             h2{
//                 height: 24px;
//                 font-size: 24px;
//                 line-height: 24px;
//                 margin-bottom: 12px;
//             }
//         }
//         .right_wrap{
//             width: 600px;
//             min-width: 600px;
//         }
//     }

//     .show_img{
//         padding: 56px 10px 160px;
//         max-width: 1400px;
//         width: 100%;
//         margin: auto;
//         .top{
//             display: block;
//             margin: auto;
//             width: 466px;
//         }
//         .show_img_list{
//             overflow: hidden;
//             margin:64px auto 0;
//             width: 100%;
//             .item_img{
//                 float: left;
//                 width: 32%;
//                 height: 350px;
//                 object-fit: cover;
//                 margin-bottom: 2%;
//                 &:hover{
//                     opacity: .55;
//                 }
//                 &:active{
//                     opacity: .15;
//                 }
//                 &:nth-child(3n+2){
//                     margin: 0 2% 2%;
//                 }
//             }
//         }
//     }
// }

// @media screen and (max-width: 1200px) {
//     .wrap{
//         .top_wrap{
//             height: 550px;
//             video{
//                 height: 550px;
//             }
//             img{
//                 width: 20%;
//             }
//         }
//     }
//     .con_wrap{
//         padding: 100px 10px 12px;
//         display: flex;
//         width: 100%;
//         min-width: 1000px;
//         margin: auto;
//         .left_wrap{
//             width: 100%;
//             margin-right: 36px;
//             div{
//                 margin-bottom: 24px;
//             }
//             h2{
//                 height: 24px;
//                 font-size: 24px;
//                 line-height: 24px;
//                 margin-bottom: 12px;
//             }
//         }
//         .right_wrap{
//             width: 500px;
//             min-width: 500px;
//         }
//     }

//     .show_img{
//         padding: 46px 10px 120px;
//         max-width: 1400px;
//         width: 100%;
//         margin: auto;
//         .top{
//             display: block;
//             margin: auto;
//             width: 406px;
//         }
//         .show_img_list{
//             overflow: hidden;
//             margin:48px auto 0;
//             width: 100%;
//             .item_img{
//                 float: left;
//                 width: 32%;
//                 height: 300px;
//                 object-fit: cover;
//                 margin-bottom: 2%;
//                 &:hover{
//                     opacity: .55;
//                 }
//                 &:active{
//                     opacity: .15;
//                 }
//                 &:nth-child(3n+2){
//                     margin: 0 2% 2%;
//                 }
//             }
//         }
//     }
// }
</style>

<style lang="less" >
.show_img_list{
    .van-grid-item__content{
        padding: 0;

    }
}
.con_wrap{
    padding: .24rem 0 .12px;
    text-align: left;
    div{
        img{
            max-width: 100%;
        }
        .ql-align-center{
            font-size: 0;
        }
    }
}
</style>




