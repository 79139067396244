/*
 * @Descripttion: 三食文化
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-02-06 21:51:39
 * @LastEditors: yuqy
 * @LastEditTime: 2021-05-08 21:07:08
 * @model: -
 */

// import Vue from 'vue'

const creatForm = (param)=>{
    let params = new FormData() // 创建form对象
    for (const key in param) {
        if(key == 'file'){
            params.append(key, param[key],param['file'].name) // 通过append向form对象添加数据
        }else if(key == 'p_physical_file'){
            param[key].forEach((element,index) => {
                params.append(key+'['+index+']', element,element.name)
            });
           
        }else{
            params.append(key, param[key]) // 通过append向form对象添加数据
        }
       
    }
    // const token = Vue.ls.get('token');
    // if(token){
    //     params.append('token', token);
    // }
    return params;
}
export default creatForm