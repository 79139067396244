/*
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-06 16:01:26
 * @LastEditors: yuqy
 * @LastEditTime: 2021-05-10 00:10:05
 * @model: -
 */
// import Vue from 'vue'
import axios from 'axios'
import { VueAxios } from './axios'
// import store from '@/store/mobile'
// import {Modal, notification} from 'ant-design-vue'

import { HOST } from '@/config/index'
let HOST_='';
if(process.env.NODE_ENV === 'production') {//生产环境的域名
  if(localStorage.CTX == '/mobileApi'){
    HOST_ = HOST.mobile;
  }else{
    HOST_ = HOST.pc;
  }
}

// 创建 axios 实例
const service = axios.create({
  baseURL: HOST_, // api base_url  本地dev
  timeout: 20000 ,// 请求超时时间
  withCredentials:true
})

const err = (error) => {
  if (error.response) {

    // let data = error.response.data
    switch (error.response.status) {
      case 403:
       
        break
      case 500:
        
        // notification.error({ message: '系统提示', description:'服务器错误',duration: 4})
        break
      case 404:
          // notification.error({ message: '系统提示', description:'很抱歉，资源未找到!',duration: 4})
        break
      case 504:
        // notification.error({ message: '系统提示', description: '网络超时'})
        break
      case 401:
        // notification.error({ message: '系统提示', description:'未授权，请重新登录',duration: 4})
        break
      default:
        // notification.error({
        //   message: '系统提示',
        //   description: data.message,
        //   duration: 4
        // })
        break
    }
  }
  return Promise.reject(error)
};

// request interceptor
service.interceptors.request.use(config => {
  config.headers = {
    'Content-Type': 'multipart/form-data'
  }
  // if (token) {
    // config.headers.token = token;
    //config.headers[ 'token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    // config.params = {
    //   ...config.params
    // }
  // }
  config.params = {
    _t: Date.parse(new Date())/1000,
    ...config.params
  }
  return config
},(error) => {
  return Promise.reject(error)
})

// response interceptor
service.interceptors.response.use((response) => {
  // 用于登录图片验证码的更新
  if(response.data.hash1){
    return response.data;
  }
  //用于导出文件
  if(!response.data.message){
    return response
  }

  if(response.data.code == 0){
    return response.data
  }else{
    return response.data || null;
  }
}, err)
const installer = {
  vm: {},
  install (Vue, router = {}) {
    Vue.use(VueAxios, router, service)
  }
}
export {
  installer as VueAxios,
  service as axios
}