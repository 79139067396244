/*
 * @Author: yuqy
 * @Date: 2021-05-07 23:56:46
 * @LastEditors: yuqy
 * @LastEditTime: 2021-08-11 20:30:36
 * @Descripttion: -
 */
const format = function(time, format){
  const t = new Date(time);
  const tf = function(i){return (i < 10 ? '0' : '') + i};
  return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a){
    let timeValue = ''
    switch(a){
      case 'yyyy':
        timeValue = tf(t.getFullYear());
        break;
      case 'MM':
        timeValue = tf(t.getMonth() + 1);
        break;
      case 'mm':
        timeValue = tf(t.getMinutes());
        break;
      case 'dd':
        timeValue = tf(t.getDate());
        break;
      case 'HH':
        timeValue = tf(t.getHours());
        break;
      case 'ss':
        timeValue = tf(t.getSeconds());
        break;
    }
    return timeValue;
  });
};
const isRealNum=function(val){
  if(val === "" || val ==null){
    return false;
  }
  if(!isNaN(val)){
    return true;
  }else{
    return false;
  }
}

export { format ,isRealNum}

