/*
 * @Descripttion: 三食文化
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-01-28 23:29:38
 * @LastEditors: yuqy
 * @LastEditTime: 2021-08-05 13:09:07
 * @model: -
 */
import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import getters from './getters'
import { postAction } from '@/api/manage'
import about from '../modules/about'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,about
  },
  state: {
    isLoading:true,
    title:'首页',
    apiCtx:'/webApi',
    articleTab:{}
  },
  mutations: {
    SET_LOADING: (state, isLoading) => {
      state.isLoading = isLoading;
    },
    SET_TAB: (state, articleTab) => {
      state.articleTab = articleTab;
    },
  },
  actions: {
    GET_TAB({commit}) {
      if (localStorage.article_class_arr) {
        let article_class_arr = JSON.parse(localStorage.article_class_arr);
        commit('SET_TAB', article_class_arr);
      }
      return new Promise((resolve) => {
        postAction('/article/index',{page:0}).then((res)=>{
          if(res.code == 0){
            localStorage.article_class_arr = JSON.stringify(res.data.article_class_arr);
            commit('SET_TAB', res.data.article_class_arr);
            resolve(res)
          }
        })
      })
    }
  },
  getters
})
