<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-06 13:37:54
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-17 23:02:46
 * @model: -
-->
<template>
  <div id="app">
    <router-view ></router-view>
  </div>
</template>

<script>
// import HelloWorld from '../../components/HelloWorld.vue'

export default {
  name: 'App',
  components: {},
  computed:{

  },
  created() {
    console.log(this);
  }
}
</script>

<style lang='less'>
 html,body{
   background: #ffffff;
 }

 .ivu-spin-fix{
   z-index: 9999;
 }


#app{
  min-width: 1000px;
}

</style>
