/*
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-06 13:37:54
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-17 22:15:21
 * @model: -
 */
import Vue from 'vue'
import App from './index.vue'
import createRouter from '../../router/index/index.js'
import store from '@/store/index/index'
Vue.config.productionTip = false
import { HOST } from '@/config/index'
Vue.config.CTX = HOST.WCTX 


import '../../my-theme/index.less';
import ViewUI from 'view-design'; //引入UI框架
import 'view-design/dist/styles/iview.css';

Vue.use(ViewUI);

import VueAMap from 'vue-amap';
Vue.use(VueAMap);
// //地图
VueAMap.initAMapApiLoader({
  key: '66f6d941abf8c6c332d80db2a4443fa9',
  plugin: ['Autocomplete', 'PlaceSearch', 'Scale', 'OverView', 'ToolBar', 'MapType', 'PolyEditor', 'AMap.CircleEditor',"AMap.Autocomplete", "AMap.Geocoder", "AMap.Geolocation"],
  v: '1.4.4',
  uiVersion: "1.1"
});

// import Vant from 'vant';
import 'vant/lib/index.css'; //引入vantui框架
// Vue.use(Vant);

// 图片预览使用的控件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)
Viewer.setDefaults({
  Options: {
    'inline': true,
    'button': true,
    'navbar': false,
    'title': true,
    'toolbar': true,
    'tooltip': true,
    'movable': true,
    'zoomable': true,
    'rotatable': true,
    'scalable': true,
    'transition': true,
    'fullscreen': true,
    'keyboard': true,
  }
})

new Vue({
  router:createRouter,
  render: h => h(App),
  store
}).$mount('#app')
