<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-06 00:12:59
 * @LastEditors: yuqy
 * @LastEditTime: 2021-09-02 11:38:06
 * @model: 文章详情
-->

<template>
  <div style="background:#F5F7FA;padding-bottom:112px;">
    <div class="guide_wrap">
        <router-link  tag="a" to="/home/article" >原创文章</router-link>
        <Icon type="ios-arrow-forward" size='20' style="margin:0 12px;"/>
        <router-link  tag="a" to="/home/article" >{{ class_name }}</router-link>
        <Icon type="ios-arrow-forward" size='20' style="margin:0 12px;"/>
        <span>{{conData.art_title}}</span>
    </div>

    <div class="wrap">
      <Spin size="large" fix v-if="isLoading" ></Spin>
      <!-- 置顶图图 -->
      <div class="input_wrap">
          <h3>{{ conData.art_title }}</h3>
          <p>{{ conData.art_updatetime_cn }}</p>
      </div>
      <!-- 文章详情 -->
      <div class="con_wraps" v-html="conData.art_desc"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';

Vue.use(Lazyload);
import { postAction } from '@/api/manage'
import { mapState } from 'vuex'


export default {
  name: 'App11',
  components: {
  },
  data(){
    return {
      value:0,
      conData:{},
      isLoading:true,
      class_name:'全部'
    }
  },
  computed: {
    ...mapState({
      articleTab:state => state.articleTab
    })
  },
  created() {},
  watch:{
    $route: function(newRoute) {
      console.log(newRoute)
      let art_id = this.$route.params.id;
      if(!this.articleTab[1]){
        this.$store.dispatch('GET_TAB');
      }
      this.getCon(art_id);
    }
  },
  activated() {
    let art_id = this.$route.params.id;
    if(!this.articleTab[1]){
      this.$store.dispatch('GET_TAB');
    }
    this.getCon(art_id);
  },
  methods:{
    getCon(art_id) {
      postAction('/article/detail',{art_id}).then((res)=>{
        this.isLoading = false;
        if(res.code == 0){
          this.conData = res.data;
          if(this.articleTab.length > 0) {
            this.articleTab.forEach(element => {
              if (element.class_id == res.data.class_id) {
                this.class_name = element.class_name;
              }
            });
          }else{
            this.class_name = '全部';
          }
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.guide_wrap{
  max-width: 1400px;
  min-width: 800px;
  width: 14rem;
  margin:0 auto ;
  padding: .28rem 0;
  a{
    height: .20rem;
    font-size: .20rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3B3B3B;
    line-height: .19rem;
    &:hover{
      color: #00C99D;
    }
  }
  span{
    height: .20rem;
    font-size: .20rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #3B3B3B;
    line-height: .19rem;
    color: #00C99D;
  }
}
.wrap{
  max-width: 1400px;
  min-width: 800px;
  width: 14rem;
  margin:0 auto ;
  background: #fff;
  border-radius: 5px;
  padding: .46rem 0 1.00rem;
}



.input_wrap{
    padding: 6px 1.00rem .28rem;
    margin: 0 .42rem 0;
    border-bottom: 1px solid #F0F0F0;
    h3{
        margin: 0 0 .20rem;

        height: .21rem;
        font-size: .20rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
        line-height: .19rem;
    }
    p{
        height: .12rem;
        font-size: .14rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #3B3B3B;
        line-height: .19rem
    }
}



       

</style>


<style lang="less" >
.con_wraps {
    padding: .36rem 1.42rem;
    border-radius: 10px;
    text-align: left;
    img{
        max-width: 100%;
    }
    p{
      // font-size: 16px;
    }

}
</style>




