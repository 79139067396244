/*
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-06 16:01:26
 * @LastEditors: yuqy
 * @LastEditTime: 2021-04-19 11:48:22
 * @model: -
 */
// import Vue from 'vue'
// import { postAction } from '@/api/manage'


const user = {
  namespaced: true,//是否起名字，如果有名字需要带上前缀
  state: {
    api_token: '',
    username: '',
    realname: '',
    welcome: 'hellow word',
    avatar: '',
  },

  mutations: {
    SET_TOKEN: (state, api_token) => {
      state.api_token = api_token;
    },
    SET_NAME: (state, { username, realname, welcome }) => {
      state.username = username
      state.realname = realname
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    }
  },

  actions: {
    // 获取用户权限列表
    // GetUserRole({ commit }, role){
     
    // },
    
    // 找回密码
    
    SetStep({ commit },step) {
      return new Promise((resolve) => {
        commit('SET_STEP', step.step)
        resolve()
      })
    }
  }
}

export default user
