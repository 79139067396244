<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-05 22:25:18
 * @LastEditors: yuqy
 * @LastEditTime: 2021-07-15 19:56:40
 * @model: 体检报告
-->

<template>
  <div>
    <div class="wrap">
      <!-- 置顶图图 -->
      <div class="loop_wrap">
        <img src="@/assets/linkpc.png" alt />
      </div>
      <div class="map_wrap" v-if="markers.length > 0">
        <h2 class="animate__animated animate__fadeInDown">
          <img src="@/assets/about/tel_all.png" alt />
          全国统一服务热线：{{about.data.module_list.phone}}
        </h2>
        <div class="map_inner">
          <div class="map animate__animated animate__slideInLeft" @click="openMap">
            <el-amap
              vid="amap-show-1"
              :center="center"
              style="height:517px;"
              zoom="18"
              :dragEnable="false"
              :zoomEnable="false"
              :doubleClickZoom="false"
              :keyboardEnable="false"
            >
              <el-amap-marker v-for="(marker, index) in markers" :position="marker" :key="index"></el-amap-marker>
            </el-amap>
          </div>
          <div
            v-if="about"
            class="input_wrap animate__animated animate__slideInRight"
            v-html="about.data.module_list.contact_message"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";
Vue.use(Lazyload);
import { mapState } from "vuex";
import "@/my-theme/animate.css";

export default {
  name: "about",
  components: {},
  data() {
    return {
      center: [0, 0],
      markers: [],
      map: null,
      address: {},
      value: 0
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
      about: state => state.about.about
    })
  },
  created() {
    if (!this.about) {
      this.$store.dispatch("about/GET_ABOUT").then(res => {
        this.markers = [];
        let company_address = res.data.module_list.company_address;

        if (company_address.location_map_x) {
          let arr = [];
          arr.push(company_address.location_map_y);
          arr.push(company_address.location_map_x);

          this.center[0] = company_address.location_map_y;
          this.center[1] = company_address.location_map_x;
          this.markers.push(arr);
        }
      });
    } else {
      this.markers = [];
      let company_address = this.about.data.module_list.company_address;
      if (company_address.location_map_x) {
        let arr = [];
        arr.push(company_address.location_map_y);
        arr.push(company_address.location_map_x);
        this.center[0] = company_address.location_map_y;
        this.center[1] = company_address.location_map_x;
        this.markers.push(arr);
      }
    }
    // console.log(this.about.data.module_list.company_address);
  },
  methods: {
    openMap() {
      let url =
        "https://m.amap.com/navi/?dest=" +
        this.center[0] +
        "," +
        this.center[1] +
        "&destName=" +
        this.about.data.module_list.company_address.location_ad_title +
        "&key=66f6d941abf8c6c332d80db2a4443fa9";

      // location.href = url;
      window.open(url);
    }
  }
};
</script>
<style lang="less" scoped>
.wrap {
  background: #f5f7fa;
  padding-bottom: 0.76rem;
  .loop_wrap {
    overflow: hidden;
    position: relative;
    padding: 0.63rem 0 0.44rem;
    img {
      display: block;
      margin: auto;
      object-fit: cover;
      width: 5.66rem;
    }
  }
}
.map_wrap {
  max-width: 1400px;
  min-width: 800px;
  width: 14rem;
  margin: auto;
  background: #fff;

  padding: 0.69rem 0.49rem 0.54rem;
  .map_inner {
    display: flex;
  }
  h2 {
    text-align: center;
    height: 0.48rem;
    font-size: 0.5rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #00c99d;
    margin-bottom: 0.9rem;
    img {
      width: 0.6rem;
      vertical-align: middle;
      margin: -4px 0.24rem 0 0;
    }
  }
  .map {
    width: 7.26rem;
    margin-right: 0.42rem;
    border-radius: 5px;
    overflow: hidden;
  }
}

// @media screen and (max-width: 1300px) {

//   .wrap {
//     padding-bottom: 56px;
//     .loop_wrap {
//       padding: 53px 0 34px;
//       img {
//         width: 466px;
//       }
//     }
//   }

//   .map_wrap{
//       max-width: 1400px;
//       min-width: 1000px;
//       margin: auto;
//       background: #fff;
//       padding: 69px 49px 54px;
//       .map_inner{
//           display: flex;
//       }
//       h2{
//           text-align: center;
//           height: 42px;
//           font-size: 40px;
//           font-family: PingFang SC;
//           font-weight: bold;
//           color: #00C99D;
//           margin-bottom: 70px;
//           img{
//               width: 50px;
//               vertical-align: middle;
//               margin: -4px 24px 0 0;
//           }
//       }
//       .map{
//           width: 50%;
//           margin-right: 42px;
//           border-radius: 5px;
//           overflow: hidden;
//       }
//   }
// }
</style>

<style lang="less">
.input_wrap {
  img {
    vertical-align: middle;
  }
}
</style>




