<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-05 22:25:18
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-23 22:50:38
 * @model: 体检报告
-->

<template>
  <div>
    <div class="wrap">
      <!-- 置顶图图 -->
      <div class="loop_wrap ">
        <img src="@/assets/reportpc.png" alt />
      </div>

      <div class="input_wrap_ ">
        <div class="input_wrap animate__animated animate__slideInLeft">
          <h3>体检报告免费解读申请</h3>
          <p>上传体检报告，免费获取营养专家深度解读和专属身体调养方案</p>
          <div class="list_input">
            <h4>体检人姓名：</h4>
            <van-field
              v-model.trim="p_contact_realname"
              label
              placeholder="请输入姓名"
              style="background:#F5F7FA;"
            />
          </div>
          <div class="list_input">
            <h4>微信号：</h4>
            <van-field
              v-model="p_contact_wechat"
              label
              placeholder="请输入微信号"
              style="background:#F5F7FA;"
            />
          </div>
          <div class="list_input">
            <h4>手机号：</h4>
            <van-field
              type="number"
              v-model.trim="p_contact_mobile"
              label
              placeholder="请输入手机号"
              style="background:#F5F7FA;"
            />
          </div>
          <div class="list_input">
            <h4>验证码：</h4>
            <div class="code_input_wrap">
              <van-field
                v-model.trim="code"
                label
                placeholder="请输入验证码"
                style="background:#F5F7FA;"
              />
              <img :src="code_img" alt @click="getRefreshCode" />
            </div>
          </div>

          <div class="list_input">
            <h4>手机验证码：</h4>
            <div class="code_input_wrap">
              <van-field
                type="number"
                v-model.trim="mobile_vcode"
                label
                placeholder="请输入手机验证码"
                style="background:#F5F7FA;"
              />
              <div class="get_code" @click="sendCode">{{code_text}}</div>
            </div>
          </div>

          <div class="list_input">
            <h4>希望专家回复方式：</h4>
            <!-- <van-field v-model="value" label="" placeholder="请输入用户名" style="background:#F5F7FA;"/> -->
            <!-- <van-dropdown-menu style="background:#F5F7FA;">
              <van-dropdown-item v-model="p_contact_reply_type" :options="option1" />
            </van-dropdown-menu> -->

            <Select v-model="p_contact_reply_type" style="width:100%;" class="select_type">
                <Option v-for="item in option1" :value="item.value" :key="item.value">{{ item.text }}</Option>
            </Select>
          </div>
          <div class="list_input">
            <h4>选择体检报告上传：<span>（最多上传5个文件，单个文件大小不超过20M)</span></h4>
            <!-- <p>（最多上传5个文件，单个文件大小不超过20m）</p> -->
            <div class="upload_img">
              <van-uploader
                :accept="accept"
                :max-size="20 * 1024 * 1024"
                @oversize="onOversize"
                v-model="fileList"
                :after-read="afterRead"
                multiple
                :max-count="5"
              />
            </div>
          </div>

          <div class="btn_wrap">
            <van-button type="primary" @click="btnSave">
              <img src="@/assets/footer/doctor.png" alt="">提交申请
              </van-button> 
          </div>
        </div>
        <div class="right_wrap animate__animated animate__slideInRight">
          <img src="@/assets/report/report_bg.png" alt />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload, Field, DropdownMenu, DropdownItem, Uploader } from "vant";

Vue.use(Field);
Vue.use(Lazyload);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Uploader);

import '@/my-theme/animate.css'
import { Toast } from "vant";
Toast.setDefaultOptions({ duration: 3000 });
import { getAction, postAction } from "@/api/manage";
import { HOST } from "@/config/index";
let HOST_ = HOST.WCTX;
if (process.env.NODE_ENV === "production") {
  //生产环境的域名
  HOST_ = HOST.pc;
}

export default {
  name: "App11",
  components: {},
  data() {
    return {
      images: [
        "https://img01.yzcdn.cn/vant/apple-1.jpg",
        "https://img01.yzcdn.cn/vant/apple-2.jpg"
      ],
      value: 0,
      value1: 0,
      p_contact_realname: "",
      p_contact_wechat: "",
      p_contact_mobile: "",
      p_contact_reply_type: 2,
      mobile_vcode: "",
      code: "",

      option1: [
        { text: "电话", value: 1 },
        { text: "微信", value: 2 }
      ],
      fileList: [],
      code_img: `${HOST_}/common/captcha`,
      num: 120,
      code_text: "验证码",

      accept:
        "image/*,.pdf,video/*,audio/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    };
  },
  created() {
  },
  methods: {
    afterRead(file) {
      console.log(file);
      // file.status = 'uploading';
      // file.message = '上传中...';

      // setTimeout(() => {
      //     file.status = 'failed';
      //     file.message = '上传失败';
      // }, 1000);
    },
    onOversize(file) {
      console.log(file);
      Toast("文件大小不能超过 20M");
    },
    getRefreshCode() {
      let code_img = `${HOST_}/common/captcha`;
      getAction(code_img + "?refresh", {}).then(res => {
        console.log(res);
        this.code_img = code_img + "?time=" + new Date().getTime();
      });
    },
    btnSave() {
      let reg = /^1[123456789][0-9]{9}$/;
      if (!this.p_contact_realname) {
        Toast("请输入姓名");
        return false;
      }
      if (!this.p_contact_wechat) {
        Toast("请输入微信号");
        return false;
      }
      if (!this.p_contact_mobile) {
        Toast("请输入手机号");
        return false;
      }
      if (!reg.test(this.p_contact_mobile)) {
        Toast("手机号格式错误");
        return false;
      }
      if (!this.mobile_vcode) {
        Toast("请输入验证码");
        return false;
      }
      if (!this.p_contact_reply_type) {
        Toast("请选择回复方式");
        return false;
      }
      if (this.fileList.length == 0) {
        Toast("请选择文件");
        return false;
      }
      let param = {
        p_contact_realname: this.p_contact_realname,
        p_contact_wechat: this.p_contact_wechat,
        p_contact_mobile: this.p_contact_mobile,
        mobile_vcode: this.mobile_vcode,
        p_contact_reply_type: this.p_contact_reply_type,
        p_physical_file: []
      };
      this.fileList.forEach(item => {
        param.p_physical_file.push(item.file);
      });
      // this.$store.commit("SET_LOADING", true);
      postAction("/physical/upload", param).then(res => {
        if (res.code == 0) {
          // this.$store.commit('SET_LOADING',false);
          Toast.success("提交成功");
          this.fileList = [];
        } else {
          Toast.fail(res.message);
          this.num = 0;
          this.lastTime();
        }
      });
    },
    sendCode() {
      let reg = /^1[123456789][0-9]{9}$/;
      if (!this.p_contact_mobile) {
        Toast("请输入手机号");
        return false;
      }
      if (!reg.test(this.p_contact_mobile)) {
        Toast("手机号格式错误");
        return false;
      }
      if (!this.code) {
        Toast("请输入图形验证码");
        return false;
      }
      if (this.num < 120) {
        Toast("请勿频繁获取验证码");
        return false;
      }
      let param = {
        mobile: this.p_contact_mobile,
        pic_code: this.code,
        sms_type: "www_upload_physical"
      };
      postAction("/common/getmobilecode", param).then(res => {
        if (res.code == 0) {
          // console.log(res);
          Toast.success("发送成功");
          this.lastTime();
        } else {
          Toast(res.message);
          this.getRefreshCode();
        }
      });
    },
    lastTime() {
      this.num--;
      this.code_text = this.num + "s";
      setTimeout(() => {
        if (this.num > 0) {
          this.lastTime();
        } else {
          this.num = 120;
          this.code_text = "验证码";
        }
      }, 1000);
    }
  }
};
</script>
<style lang="less" scoped>
.wrap {
  background: #f5f7fa;
  padding-bottom: .76rem;
  .loop_wrap {
    overflow: hidden;
    position: relative;
    padding: .63rem 0 .44rem;
    img {
      display: block;
      margin: auto;
      object-fit: cover;
      width: 5.66rem;
    }
  }
}
.code_input_wrap {
  display: flex;
  align-items: center;
  img {
    margin-left: .12rem;
    display: block;
    width: 1.20rem;
    height: .44rem;
    min-width: 1.20rem;
    // object-fit: cover;
  }
  .get_code {
    margin-left: .12rem;
    display: block;
    width: 1.20rem;
    height: .44rem;
    min-width: 1.20rem;
    font-size: .16rem;
    line-height: .44rem;
    text-align: center;
    color: #07c160;
    cursor:default;
  }
}
.input_wrap_ {
  max-width: 14rem;
  min-width: 800px;
  width: 14rem;
  margin: auto;
  background: #fff;
  display: flex;
  padding: .69rem 0 .54rem;
  .right_wrap {
    padding-right: .65rem;
    img {
      width: 6.08rem;
      min-width: 6.08rem;
    }
  }
}
.input_wrap {
  padding: 0px 1.05rem;
  width: 100%;
  h3 {
    height: .29rem;
    font-size: .30rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #3B3B3B;
    line-height: .19rem;
    margin-bottom: .24rem;
  }
  p{
    height: .18rem;
    font-size: .18rem;
    font-family: PingFang SC;
    font-weight: 400;
    color: #3B3B3B;
    line-height: .19rem
  }
  .list_input {
    margin-top: .20rem;
    position: relative;
    h4 {
      font-size: .18rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3b3b3b;
      margin-bottom: .08rem;
      text-align: left;
      span{
        font-size: .14rem;
      }
    }
    .van-cell {
      padding: .10rem .12rem;
    }
    p {
      font-size: .14rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3b3b3b;
      text-align: left;
    }
  }
}

.upload_img {
  text-align: left;
  padding: .12rem 0 0;
}

.btn_wrap {
  margin-top: .24rem;
  button {
    width: 100%;
    border-radius: 0;
    outline: none;
    border: 0;
    background: #00c99d;
    font-size: .18rem;
    img {
      width: .18rem;
      vertical-align: middle;
      margin: 0 .04rem 0 0;
    }

    a {
      color: #00c99d;
    }
    &:before {
      display: none;
    }
    &:hover{
      opacity: .55;
    }
  }
}

.select_type{
  background: rgb(245, 247, 250);
  padding: 6px 0;
}
</style>

<style lang="less" >
.select_type{
  .ivu-select-selection{
    background: transparent;
    border: 0 !important;
    box-shadow: none !important;
  }
  .ivu-select-item-selected, .ivu-select-item-selected:hover{
    color: #07c160;
  }
}

.list_input {
  

  .van-field__control {
    font-size: 0.16rem;
  }
  .van-dropdown-menu__bar {
    background: #f5f7fa;
    box-shadow: none;
    height: .44rem;
    .van-dropdown-menu__title {
      display: block;
      width: 98%;
      div {
        text-align: left;
        font-size: 0.125rem;
      }
    }
    .van-dropdown-menu__title--active {
      color: #00c99d;
    }
  }
}
.van-dropdown-item__option--active {
  color: #00c99d !important;
}
.van-dropdown-item__option--active .van-dropdown-item__icon {
  color: #00c99d !important;
}

.van-dropdown-menu__title {
  &:after {
    right: 10px !important;
  }
}
</style>



