/*
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-19 11:31:02
 * @LastEditors: yuqy
 * @LastEditTime: 2021-05-06 20:55:59
 * @model: -
 */
// import Vue from 'vue'
// import { login } from "@/api/login"
import { postAction } from '@/api/manage'


const about = {
  namespaced: true,//是否起名字，如果有名字需要带上前缀
  state: {
    about:null
  },

  mutations: {
    SET_ABOUT: (state, about) => {
      state.about = about;
    }
  },

  actions: {
    GET_ABOUT({ commit }) {
      return new Promise((resolve) => {
        postAction('/contact/index',{}).then((res)=>{
          if(res.code == 0){
            commit('SET_ABOUT', res);
            resolve(res)
          }
        })
      })
    },
    SetStep({ commit },step) {
      return new Promise((resolve) => {
        commit('SET_STEP', step.step)
        resolve()
      })
    }
  }
}

export default about