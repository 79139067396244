/*
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-04-06 13:44:03
 * @LastEditors: yuqy
 * @LastEditTime: 2021-07-19 23:08:00
 * @model: -
 */
import Vue from 'vue'
import Router from 'vue-router'
import main from '@/views/pc/main/index';
import store from '@/store/index/'
// import index from '../../pages/index/index.vue';
Vue.use(Router);
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '*', redirect: '/home/index', hidden: true
    },
    {
        path: '/home',
        component: main,
        redirect: '/home/index',
        meta:{title:"首页"},
        children: [
          {
            path: 'index',
            name: 'homeIndex',
            meta:{title:"首页",type:'2',class:"left1"},
            component: require('@/views/pc/home/index.vue').default
          },
          {
            path: 'brand',
            name: 'brandIndex',
            meta:{title:"品牌故事",type:'2',class:"left2"},
            component: require('@/views/pc/brand/index.vue').default
          },
          {
            path: 'article',
            name: 'articleIndex',
            meta:{title:"原创文章",type:'2',class:"left4"},
            component: require('@/views/pc/article/index.vue').default
          },
          {
            path: 'details/:id',
            name: 'detailsIndex',
            meta:{title:"文章详情",type:'2',class:"left4"},
            component: require('@/views/pc/details/index.vue').default
          },
          {
            path: 'report',
            name: 'reportIndex',
            meta:{title:"解读报告",type:'2',class:"left10"},
            component: require('@/views/pc/report/index.vue').default
          },
          {
            path: 'about',
            name: 'aboutIndex',
            meta:{title:"联系我们",type:'2',class:"left5"},
            component: require('@/views/pc/about/index.vue').default
          },
          {
            path: 'read',
            name: 'readIndex',
            meta:{title:"读书会",type:'2',class:"left3"},
            component: require('@/views/pc/readClub/index.vue').default
          },
        ]
      },

];
const createRouter = new Router({
    // mode: 'history',
    routes,
});

//路由开始加载
createRouter.beforeEach((to, from,next) => {
    store.commit('SET_LOADING',true);
    document.documentElement.scrollTop = 0
    next();
})
//加载完毕
createRouter.afterEach(() => {
    store.commit('SET_LOADING',false);
})
export default createRouter;