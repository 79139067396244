/*
 * @Descripttion: 三食文化
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-02-03 23:11:00
 * @LastEditors: yuqy
 * @LastEditTime: 2021-06-30 23:11:14
 * @model: -
 */
export const HOST = {//测试域名
    mobile:process.env.VUE_APP_BASEURL_MOBILE || '',
    pc:process.env.VUE_APP_BASEURL_PC || '',
    MCTX:'/mobileApi',  //用来校验，域名
    WCTX:'/webApi',  //用来校验，域名
}; 
// export const HOST = 'https://tback.sanshibook.com/'; //测试域名域名
export const USER_ID = '273802409313506055'; //阿里云上传的用户id