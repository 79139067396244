<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-05 17:10:14
 * @LastEditors: yuqy
 * @LastEditTime: 2021-09-02 11:45:22
 * @model: pc端导航页面
-->
<template>
  <div id="guide">
    <Spin size="large" fix v-if="isLoading" ></Spin>
    <!-- 页头导航部分 -->
    <header>
      <img class="logo " src="@/assets/logo_pc.png" alt="" srcset="">
      <div class="router">
          <img :class="className" src="@/assets/loop_active.png" alt="">
          <router-link  tag="a" :class="className == 'left1'?'active':''" to="/home" >首页</router-link>
          <router-link  tag="a" :class="className == 'left2'?'active':''" to="/home/brand" >品牌故事</router-link>
          <router-link  tag="a" :class="className == 'left3'?'active':''" to="/home/read" >读书会</router-link>
          <router-link  tag="a" :class="className == 'left4'?'active':''" to="/home/article" >原创文章</router-link>
          <router-link  tag="a" :class="className == 'left5'?'active':''" to="/home/about" >联系我们</router-link>
      </div>
      <div class="report" >
        <router-link tag="a" to="/home/report" >体检免费解读</router-link>
      </div>
    </header>
    <div class="content_wrap">
      <keep-alive>
        <router-view />     
      </keep-alive>
    </div>
    <!-- 页脚 -->
    <footer>
      <div class="footer_con">
        <div class="left_wrap" v-if="about" v-html="about.data.bottom.contact_message">
          <!-- {{about.data.bottom.contact_message}} -->
          <!-- <h2>联系我们</h2>
          <p><img src="@/assets/footerpc/time.png" alt="">工作时间：10:00-19:00（工作日）</p>
          <p><img src="@/assets/footerpc/tel1.png" alt="">联系电话：{{about.data.module_list.phone}}</p>
          <p><img src="@/assets/footerpc/hh.png" alt="">商务合作：BD@sanshibook.cn</p>
          <p>
            <img src="@/assets/footerpc/address.png" alt="">
            <span>
              公司地址：
              {{ about.data.module_list.company_address.location_ad_title }} {{about.data.module_list.company_address.location_ad_detail}}
            </span>
            </p> -->
        </div>
        <div class="right_wrap" v-if="about">
          <div class="code_item"  v-for="(item,index) in about.data.bottom.bottom_qrcode" :key='index'>
            <h3>{{ item.qr_title }}</h3>
            <img :src="item.qr_img" alt="">
          </div>
        </div>
      </div>
      <div class="footer_desc">
        版权所有  @ 三食文化    上海三食文化传播有限公司     <a href="https://beian.miit.gov.cn/#/Integrated/index">沪ICP备2020037553号-1 </a>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue';
import { Toast,Button} from 'vant';
Vue.use(Toast);
Vue.use(Button);
// import { postAction } from '@/api/manage'

export default {
  
  name: 'guideMain',
  components: {
   
  },
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
      title:state => state.title,
      about:state => state.about.about
    })
  },
  data(){
    return{
      className:'',
      name:'',
      down:false,//是否展开，全部
      menuDown:false,//是否展开，全部菜单
    }
  },
  watch:{
    $route: function(newRoute) {
      this.className = newRoute.meta.class;
    },
    isLoading() {}
  },
  created() {
    let width = document.documentElement.clientWidth
    // 进行判断宽度大于该宽度就，蹦到pc端
    if(width < 800) {
      // location.href = './mobile.html';
      let url_ = location.href;
      let url = process.env.VUE_APP_CURRENTMODE === 'sit'?'https://twww.sanshibook.com':'https://www.sanshibook.com'
      if (process.env.VUE_APP_CURRENTMODE === 'sit') {
        url = url_.replace('twww','tm')
      } else {
        url = url_.replace('www.sanshibook','m.sanshibook')
      }
      // console.log(url);
      location.href = url;
    }
    this.className = this.$route.meta.class;
    if(!this.about){
        this.$store.dispatch('about/GET_ABOUT');
    }
  },
  methods:{
    // getBootm() {

    // }
  }
}
</script>

<style lang='less' scoped>
.content_wrap{
  position: relative;
  z-index: 9;
  // padding-top: 48px;
   border-top: 1px solid #F5F7FA;
}
#guide{
  position: relative;
}
header{
  max-width: 1400px;
  min-width: 800px;
  width: 14rem;
  margin: auto;
  height: .88rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
 
  .logo{
    width: 1.76rem;
  }
  .router{
    position: relative;
    margin-left: 3.06rem;
    // margin-right: 204px;
    white-space: nowrap;
    img{
      position: absolute;
      width: .20rem;
      top: .30rem;
      left: .07rem;
      transition: all .5s;
      opacity: 1;
      height: auto;
      &.left2{
        left: 1.30rem;
        transition: all .5s;
      }
      &.left3{
        left: 2.60rem;
        transition: all .5s;
      }
      &.left4{
        left: 3.90rem;
        transition: all .5s;
      }
      &.left5{
        left: 5.30rem;
        transition: all .5s;
      }
      &.left10{
        opacity: 0;
      }
    }
    a{
      display: inline-block;
      margin-right: .75rem;
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      &.active{
        font-weight: bold;
      }
      &:nth-last-child(1){
        margin-right: 0;
      }
    }
  }
  .report{
    width: 1.24rem;
    min-width: 1.24rem;
    height: .31rem;
    background: #00C99D;
    border-radius: .16rem;
    line-height: .32rem;
    text-align: center;
    a{
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
a:hover{
  opacity: 0.65;
}
footer{
  background: #444855;
  .footer_con{
    padding: .82rem 0 1.06rem;
    max-width: 14rem;
    min-width: 10.00rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .right_wrap{
      display: flex;
      width: 7.92rem;
      min-width: 7.92rem;
      .code_item{
        margin-left: .54rem;
        h3{
          height: .72rem;
          font-size: .18rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #fff;
          text-align: center;
        }
        img{
          width: 1.44rem;
          height: 1.44rem;
          object-fit: cover;
        }
      }
    }
    .left_wrap{
      font-size: .18rem;
      color: #fff;
      h2{
        height: .72rem;
        font-size: .18rem;
        font-family: PingFang SC;
        font-weight: 400;
      }
      p{
        font-size: .18rem;
        text-align: left;
        padding: 0 0 ;
        margin-bottom: .34rem;
        font-family: PingFang SC;
        font-weight: 400;
        overflow: hidden;
        display: flex;
        align-items: flex-start;
        img{
          width: .24rem;
          margin: 0px .10rem 0 0;
          vertical-align: middle;
        }
        span{
          display: block;
        }
      }
      
    }
  }
}
.footer_desc{
  background: #fff;
  text-align: center;
  height: .76rem;
  font-size: .14rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #3B3B3B;
  line-height: .76rem;
  a{
    color: #3B3B3B;
  }
}


@media screen and (max-width: 1400px) {
   header{
     height: 66px;
     .router{
        img{
          position: absolute;
          width: 16px;
          top: 24px;
          left: 5px;
          &.left2{
            left: 96px;
            transition: all .5s;
          }
          &.left3{
            left: 198px;
            transition: all .5s;
          }
          &.left4{
            left: 298px;
            transition: all .5s;
          }
          &.left5{
            left: 402px;
            transition: all .5s;
          }
        } 
      a{
        font-size: 13px;
      }
    }

    .report{
      width: 88px;
      min-width: 94px;
      height: 26px;
      background: #00C99D;
      border-radius: 13px;
      line-height: 24px;
      text-align: center;
      a{
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
    }
  }
}



@media screen and (max-width: 1300px) {
  
}


@media screen and (max-width: 1200px) {
   
}


</style>
