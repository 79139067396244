<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-06 21:12:40
 * @LastEditors: yuqy
 * @LastEditTime: 2021-09-21 16:42:18
 * @model: 原创文章
-->


<template>
  <div>
    <div class="wrap">
        <div class="menu_wrap">
            <ul>
                <li :class="articleTabIndex == 0?'active':''" @click="tabChoose(0)">
                    <div class="tab_icon">
                        <img src="@/assets/article/menu.png" class="active" alt="">
                    </div>
                    全部
                </li>
                <li :class="articleTabIndex == tab.class_id?'active':''" v-for="(tab,index) in articleTab" :key='index' @click="tabChoose(tab.class_id)">
                    <div class="tab_icon">
                        <img :src="tab.class_img" class="active" alt="">
                    </div>
                    {{tab.class_name}}
                </li>
                
            </ul>
        </div>
        <div class="con_wrap">   
            <Spin size="large" fix v-if="isLoading" ></Spin>
            <div class="item" v-for="(item,index) in list" :key='index' @click="go(item)">
                <img v-lazy="item.art_cover_img" class="icon_img"  alt="">
                <img class="img_go" src="@/assets/go.png" alt="">
                <h2>{{ item.art_title }} </h2>
                <div class="time">
                    {{item.art_input_date_cn}} 
                </div>

                <div v-html="item.art_short_desc" class="p"></div>
            </div>

            <div class="no_data" v-if="totalCount == 0">
                <img src="@/assets/no_data.png" alt="">
                <p>暂无数据</p>
            </div>

            <div class="page_wrap" v-if="totalCount > 10">
                <Page :total="totalCount"  show-elevator @on-change="pageChange"/>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Lazyload } from 'vant';
import { PullRefresh } from 'vant';
import { format } from "@/utils/timeSet"
 console.log(format)
Vue.use(PullRefresh);
Vue.use(Lazyload);
import { mapState } from 'vuex'
import { postAction } from '@/api/manage'


export default {
  name: 'about',
  components: {
  },
  computed: {
    ...mapState({
      articleTab:state => state.articleTab
    })
  },
  data(){
    return {
        loading: false,
        finished: false,
        refreshing: false,
        value:0,
        active: 0,
        list:[],
        tag_arr:{},
        articleTabIndex:0,
        isLoading:true,
        totalCount:1,
        param:{
            page:0,
            page_size:12,
            search_key:'',
            class_id:0
        }
    }
  },
  watch:{
    active() {
        
    }
  },
  created() {
      if(!this.articleTab[1]){
        this.$store.dispatch('GET_TAB');
      }
      this.getList();
  },
  
  methods:{
    tabChoose(index) {
        this.articleTabIndex = index;
        this.param.page = 0;
        this.param.class_id = index;
        this.getList();
    },
     pageChange(page) {
        this.param.page = page*1 - 1;
        this.getList();
     },
     go(item) {
         let id = item.art_id;
         if(item.art_type == 2){//跳转外链
            location.href = item.art_jumpurl;
            return;
         }
         this.$router.push({ path: './details/'+id });
     } ,
     onLoad() {

     },
     getList() {
         this.isLoading = true;
        postAction('/article/index',this.param).then((res)=>{
            this.isLoading = false;
            if(res.code == 0){
                this.list = res.data.list;
                this.list.forEach(item => {
                    item.art_input_date_cn = format(item.art_input_date * 1000, 'yyyy-MM-dd')
                })
                this.totalCount = res.data.pages.totalCount
            }
        }) 
     },
     search() {
        // if(!this.param.search_key) {
        //     this.$toast({
        //         message: '请输入关键词',
        //         position: 'top',
        //     });
        //     return;
        // }
        this.param.page = 0;
        this.list = []; 
        this.$store.commit('SET_LOADING',true);
        this.getList();
     }
  }
}
</script>
<style lang="less" scoped>
.no_data{
    text-align: center;
    padding: .40rem 0 2.00rem;
}
.wrap{
    background: #fff;
    max-width: 1410px;
    min-width: 800px;
    width: 14.10rem;
    margin: auto;
    // display: flex;
    position: relative;
    padding: .56rem 0 1.15rem .10rem;
    overflow: hidden;
    
    .con_wrap{
        float: left;
        width: calc(~"100% - 2.20rem");
        overflow: hidden;
        padding: 0;
        position: relative;
        min-height: 3.00rem;

        .item{
            &:hover{
                transform: scale(1.01);
                transform-origin:center center ;
            }
            margin-bottom: .24rem;
            float: left;
            width: 32%;
            overflow: hidden;
            box-sizing: border-box;
            cursor: pointer;
            // box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.08);
            border-radius: 6px;
            position: relative;
            .icon_img{
                width: 100%;
                height: 2.85rem;
                border-radius: 6px 6px 0 0 ;
            }
            h2{
                height: .22rem;
                font-size: .22rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #3B3B3B;
                line-height: .24rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin-right: .50rem;
                margin-top: .36rem;
                position: relative;
                
            }
            .img_go{
                position: absolute;
                width: .42rem;
                top: 3.18rem;
                right: 0px;
                margin: auto;  
            }
            .p{
                height: .44rem;
                font-size: .16rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #3B3B3B;
                line-height: .22rem;
                margin-top:.24rem;
                overflow: hidden;
                text-overflow: ellipsis;
                
                
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .time{
                height: 12px;
                font-size: .14rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #3B3B3B;
                line-height: 17px;
                margin-top: .28rem;
            }
            &:nth-child(3n+2){
                margin: 0 2%;
            }
        }
    }
  .menu_wrap{
        float: left;
        width: 1.60rem;
        min-width: 1.60rem;
        margin-right:.40rem ;
        box-shadow: 0 2px 10px rgba(0, 0, 0, .15);
        padding: .24rem 0;
        border-radius: 4px;
        height: auto;

        ul{
            li{
                padding: 0 0 0 .12rem;
                margin-bottom: .60rem;
                .tab_icon{
                    width: .24rem;
                    height: .24rem;
                    overflow: hidden;
                    vertical-align: middle; 
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 0;
                    margin-top: -2px;
                    img{
                        width: .48rem;
                    //     &.active{
                    //         // display: none;
                    //     }
                    }
                }
                &:nth-last-child(1){
                    margin-bottom: 0;
                }
                
                font-size: .20rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #3B3B3B;
                line-height: .32rem;
                cursor: default;
                &.active{
                    color: #55DBBE;
                    font-weight: bold;
                    img{
                        transform: translateX(-50%);
                    }
                }

                &:hover{
                    color: #55DBBE;
                    font-weight: bold;
                    img{
                        // display: none;
                        // &.active{
                        //     display: inline-block;
                        // }
                        transform: translateX(-50%);
                    }

                }
            }
        }
  } 
}



.page_wrap{
    text-align: center;
    padding-top: 1.08rem;
    float: left;
    width: 100%;
}


// @media screen and (max-width: 1300px) {
//    .wrap{
//        min-width: 1000px;
//        padding: 46px 0 85px 10px;
//        .con_wrap{
//             float: left;
//             width: calc(~"100% - 220px");
//             overflow: hidden;
//             padding: 0;
//             position: relative;
//             min-height: 200px;
            

//             .item{
//                 margin-bottom: 24px;
//                 float: left;
//                 width: 32%;
//                 .icon_img{
//                     height: 200px;
//                 }
//                 .img_go{
//                     top: 226px;  
//                     width: 36px;
//                 }
//                 h2{
//                     height: 22px;
//                     font-size: 20px;
//                     line-height: 24px;
//                     overflow: hidden;
//                     text-overflow: ellipsis;
//                     white-space: nowrap;
//                     margin-right: 40px;
//                     margin-top: 24px;
                    
//                 }

//                 .time{
//                     height: 12px;
//                     font-size: 14px;
//                     font-family: PingFang SC;
//                     font-weight: 400;
//                     color: #3B3B3B;
//                     line-height: 17px;
//                     margin-top: 12px;
//                 }

//                 .p{
//                     height: 44px;
//                     font-size: 14px;
//                     margin-top: 12px;
//                 }
//             }
            
//        }

//        .menu_wrap{
//             ul{
//                 li{
//                     padding: 0 0 0 12px;
//                     margin-bottom: 40px;
//                     .tab_icon{
//                         width: 20px;
//                         height: 20px;
//                         img{
//                             width: 40px;
//                         }
//                     }
//                     &:nth-last-child(1){
//                         margin-bottom: 0;
//                     }
                    
//                     font-size: 18px;
//                 }
//             }
//         }

//    }
  

// }

</style>


<style lang="less" >
.page_wrap{
    .ivu-page-item-jump-next, .ivu-page-item-jump-prev, .ivu-page-next, .ivu-page-prev{
        border-radius: 50%;
    }
    .ivu-page-item{
        border-radius: 50%;
    }

    .ivu-page-options-elevator input{
        border-radius: 16px;
    }

    .ivu-page-item-active{
        border-color: #00C99D;
        background: #00C99D;
        color: #fff;
        a{
            color: #fff;
        }
    }
}


</style>





