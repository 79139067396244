<!--
 * @Descripttion: -
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-05-06 21:12:40
 * @LastEditors: yuqy
 * @LastEditTime: 2021-07-21 22:02:30
 * @model: 原创文章
-->


<template>
  <div class="read_wrap">
    <div class="wrap">
      <!-- 搜索框 -->
      <div class="top_wrap">
        <!-- <van-swipe :autoplay="3000">
          <van-swipe-item v-for="(item, index) in banner_list" :key="index">
            <img v-lazy="item.b_img" @click="go(item.b_jumpurl)" />
          </van-swipe-item>
        </van-swipe> -->

        <div class="swiper-container">
          <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(image, index) in banner_list" :key="index">
                <img :src="image.b_img" @click="go(image.b_jumpurl)" class="home_banner"/>
              </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </div>
      <!-- 精彩短视频 -->
      <div class="con_wrap">
        <img class="h2_icon" src="@/assets/videopc.png" alt />
        <div class="item_wrap" style="overflow: hidden;">
          <template v-for="(item,index) in video_list">
            <div class="item" :key="index" @click="getVideo(item.v_id)">
              <div class="img">
                <img v-lazy="item.v_video_cover" alt />
                <img src="@/assets/read/videos.png" class="icon" alt />
              </div>
              <div class="con">
                <h3>{{ item.v_title }}</h3>
                <!-- <span><img src="@/assets/read/book.png" alt /> 有机生活</span> -->
                <p>{{item.v_short_desc}}</p>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="con_wrap" style="padding-top:0;">
        <img class="h2_icon" src="@/assets/dak.png" alt />
        <div class="item_wrap">
          
          <template v-for="(item,index) in clocked_list">
            <div class="items" :key="index">
              <!-- 展示用户信息模块 -->
              <div class="header_img">
                <img :src="item.photo_img || defaultImg" alt />
                <div>
                  <h3>{{ item.nickname }}</h3>
                  <!-- 精力汤 -->
                  <p class="daka_title">
                    <span>#{{ item.act_title }}</span>
                  </p>
                  <p class="daka_con">{{ atob_(item.clocked_content) }}</p>
                  <p class="daka_time">{{ item.clocked_addtime_cn }}</p>
                </div>
              </div>
              <div class="con_img" >
                <h3 v-if='item.clocked_imgs_.length <= 1 && !item.clocked_imgs_[0]'>用户什么图片也没上传哦~</h3>
                <template v-for="(items,index) in item.clocked_imgs_">
                  <div v-if="index < 2" :key="index" class="clock_img_list">
                    <!-- <img v-lazy="items" alt /> -->
                    <viewer :images="[items]">
                      <img :src="items" v-if="items"/>
                    </viewer>
                  </div>
                </template>  
                <div v-if="item.clocked_imgs_.length > 2" class="clock_img_list" :style="'background:url('+item.clocked_imgs_[2]+');background-size:cover ;'">
                  <viewer :images="item.clocked_imgs_">
                    <p>
                      <span>更多+ <br />{{item.clocked_imgs_.length - 2}}</span>
                    </p>
                    <template v-for="(items,index) in item.clocked_imgs_">
                      <img :src="items" alt style="opacity:0;" :key='index' v-if="index >= 2"/>
                    </template>
                  </viewer>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <!-- 播放视频 -->
    <div :class="isShowVideo?'loading_wrap':'loading_wrap hide'">
      <div class="loading_bg"></div>
      <div class="del" @click="isShowVideo = false">
        <Icon type="md-close" size="48" />
      </div>
      <div class="loading_inner">
        <video :src="videoUrl" v-if="isShowVideo" controls autoplay preload></video>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Lazyload } from "vant";

Vue.use(Lazyload);
import defaultImg from "@/assets/logo.png";
import { postAction } from "@/api/manage";
import { ImagePreview } from "vant";

import Swiper from 'swiper';  
import 'swiper/dist/css/swiper.css'

export default {
  name: "about",
  components: {},
  data() {
    return {
      isShowVideo: false,
      videoUrl: "",
      defaultImg,
      banner_list: [],
      list: [],
      video_list_index: 3,
      video_list: [],
      clocked_list_index: 3,
      clocked_list: []
    };
  },
  computed: {},
  created() {
    // ImagePreview(['https://img01.yzcdn.cn/vant/apple-1.jpg']);
    this.$store.commit("SET_LOADING", true);
    this.getCon();
  },
  methods: {
    utf16to8(str) {
      var out, i, len, c;
      out = "";
      len = str.length;
      for (i = 0; i < len; i++) {
        c = str.charCodeAt(i);
        if (c >= 0x0001 && c <= 0x007f) {
          out += str.charAt(i);
        } else if (c > 0x07ff) {
          out += String.fromCharCode(0xe0 | ((c >> 12) & 0x0f));
          out += String.fromCharCode(0x80 | ((c >> 6) & 0x3f));
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
        } else {
          out += String.fromCharCode(0xc0 | ((c >> 6) & 0x1f));
          out += String.fromCharCode(0x80 | ((c >> 0) & 0x3f));
        }
      }
      return out;
    },
    utf8to16(str) {
      var out, i, len, c;
      var char2, char3;
      out = "";
      len = str.length;
      i = 0;
      while (i < len) {
        c = str.charCodeAt(i++);
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            out += str.charAt(i - 1);
            break;
          case 12:
          case 13:
            char2 = str.charCodeAt(i++);
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            char2 = str.charCodeAt(i++);
            char3 = str.charCodeAt(i++);
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }
      return out;
    },
    atob_(value) {
      try {
        let str = this.utf16to8(value);
        str = window.atob(str);
        str = this.utf8to16(str);
        return str;
      } catch (error) {
        return value;
      }
    },
    go(url) {
      if (url) {
        location.href = url;
      }
    },
    /**
     * @name: 获取播放链接
     * @msg:
     * @param {*}
     * @return {*}
     */
    getVideo(v_id) {
      this.$store.commit("SET_LOADING", true);
      postAction("/readmodule/playdata", { v_id }).then(res => {
        this.$store.commit("SET_LOADING", false);
        if (res.code == 0) {
          this.isShowVideo = true;
          this.videoUrl = res.data.video_play_data.PlayInfo[0].PlayURL;
        }
      });
    },
    /**
     * @name: 获取页面内容
     * @msg:
     * @param {*}
     * @return {*}
     */
    getCon() {
      postAction("/readmodule/index", {}).then(res => {
        this.$store.commit("SET_LOADING", false);
        if (res.code == 0) {
          // console.log(res);
          this.banner_list = res.data.banner_list;
          this.clocked_list = res.data.clocked_list.map(item => {
            item.clocked_imgs_ = item.clocked_imgs.split(",");
            return item;
          });
          this.video_list = res.data.video_list.list;

          let loopSets = {
            loop: true, // 循环模式选项
            autoplay:{
              disableOnInteraction:false,
              delay:10000,
              autoplay:true
            },
            // 如果需要分页器
            pagination: {
              el: '.swiper-pagination',
              clickable:true
            }
          }

          if(this.banner_list.length <= 1) {
            loopSets = {
              // 如果需要分页器
              pagination: {
                el: '.swiper-pagination',
                clickable:true
              }
            }
          }


          setTimeout(()=>{
            new Swiper('.swiper-container', loopSets);
          },500)
        }
      });
    },
    /**
     * @name: 预览图片
     * @msg:
     * @param {*} item
     * @return {*}
     */
    preview(index, arr) {
      //  console.log(item);
      ImagePreview({ images: arr, startPosition: index });
    }
  }
};
</script>
<style lang="less" >
.home_banner{
  display: block;
  width: 100%;
  height: 6.30rem;
  object-fit: cover;
  cursor: pointer;
  &:hover{
    opacity: 0.75;
  }
}
</style>
<style lang="less" scoped>
.loading_wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  opacity: 1;
  .loading_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    z-index: 9;
  }
  .loading_inner {
    width: 100%;
    top: 50%;
    left: 0%;
    position: absolute;
    z-index: 99;
    transform: translateY(-50%);
  }
  .del {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
    z-index: 999;
  }
  video {
    display: block;
    width: 60%;
    margin: auto;
    max-height: 65vh;
  }
  transition: all 1s;
  &.hide {
    opacity: 0;
    transition: all 1s;
    display: none;
  }
}

.wrap {
  background: #fff;
  .top_wrap {
    img {
      display: block;
      width: 100%;
    }
  }
}

.con_wrap {
  max-width: 1400px;
  min-width: 800px;
  width: 14rem;
  margin: auto;
  padding: .80rem 0px 1.00rem;
  .h2_icon {
    margin: auto;
    display: block;
    width: 5.66rem;
  }
  h2 {
    font-size: 1rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    text-align: left;
    overflow: hidden;
    padding: .18px 0;
    .h2_icon {
      width: 1.25rem;
      margin-right: 4px;
      vertical-align: middle;
      margin-top: -3px;
    }
    span {
      float: right;
      font-size: 0.8125rem;
      color: #3b3b3b;
      padding-top: 2px;
      img {
        width: 6px;
        vertical-align: middle;
        margin-left: 6px;
      }
    }
  }
}

.item_wrap {
  margin-top: .55rem;
  .items {
    margin-bottom: .16rem;
    border-radius: 8px;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.08);
    position: relative;
    padding: .22rem .23rem .22rem 5.28rem;
    margin-bottom: .78rem;
    box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.08);

    .header_img {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 5.28rem;
      height: 3.87rem;
      align-items: center;
      box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.08);
      border-radius: .40rem;
      background: #fff;

      &:hover{
          opacity: 0.55;
      }
      img {
        display: block;
        width: 1.74rem;
        height: 1.74rem;
        object-fit: cover;
        border-radius: 100%;
        margin: 0 .26rem 0 .28rem;
      }
      div {
        text-align: left;
        h3 {
          font-size: .30rem;
          font-family: PingFang SC;
          font-weight: 500;
          color: #010101;
        }
        p {
          font-size: 0.16rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #7f7f7f;
        }
      }
    }

    .daka_title {
      font-size: .20rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #00c99d;
      margin-top: .26rem;
      span {
        font-family: PingFang SC;
        font-weight: bold;
        color: #00c99d;
      }
    }

    .daka_con {
      font-size: .16rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #3b3b3b;
      padding-right: .32rem;
      max-height: 2rem;
      overflow:auto ;
    }

    .daka_time {
      font-size: .14rem;
      font-family: PingFang SC;
      font-weight: 400;
      color: #7f7f7f;
      margin-top: .16rem;
    }
    .con_img {
      overflow: hidden;
      h3{
        padding: 0 32px;
        font-weight: 400;
        font-size: 14px;
        line-height: 3.1rem;
        float: left;
      }
      .clock_img_list {
        float: left;
        width: 3.10rem;
        margin-right: .26rem;
        height: 0;
        padding-bottom: 3.04rem;
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        
        &:nth-child(3) {
          width: 16%;
          margin-right: 0;
        }
        &:nth-child(1) {
          margin-left: .37rem;
        }
        img {
          position: absolute;
          height: 100%;
          display: block;
          width: 100%;
          object-fit: cover;
          &:hover{
            opacity: 0.55;
          }
        }
        p {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.5);
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            display: inline-block;
            width: .30rem;
            color: #fff;
            font-size: .30rem;
            vertical-align: middle;
          }
        }
      }
    }
  }
  .item {
    cursor: pointer;
    margin-bottom: .80rem;
    float: left;
    width: 32%;

    &:hover{
      transform: scale(1.01);
      transform-origin:center center ;
    }
    &:nth-child(3n + 2) {
      margin: 0 2% .80rem;
    }
    .img {
      width: 100%;
      height: 3.00rem;
      overflow: hidden;
      border-radius: 5px;
      margin-right: .15rem;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .icon {
        display: block;
        position: absolute;
        width: 50%;
        height: auto;
        left: 25%;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }

    .con {
      text-align: left;
      h3 {
        height: .54rem;
        font-size: .20rem;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: .26rem;
        margin-top: .28rem;
        overflow: hidden;
      }
      span {
        display: block;
        margin-top: .12rem;
        height: .24rem;
        font-size: .16rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #00c99d;
        line-height: .30rem;
        img {
          width: .18rem;
          margin-right: 4px;
        }
      }
      p {
        height: .76rem;
        font-size: .16rem;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: .25rem ;
        overflow: hidden;
        margin-top: .12rem;
      }
    }
  }
}
// @media screen and (max-width: 1300px) {
//   .item_wrap {
//     .items {
//       padding: .22rem .23rem .22rem 6.08rem;
//       .header_img {
//         width: 6.08rem;
//         height: 3.87rem;
//       }
//     }
//   }
//  }
//  @media screen and (max-width: 1200px) {
//   .item_wrap {
//     .items {
//       padding: .22rem .23rem .22rem 6.28rem;
//       .header_img {
//         width: 6.28rem;
//         height: 3.87rem;
//       }
//     }
//   }
//  }


</style>




