/*
 * @Descripttion: 三食文化
 * @version: 1.0
 * @Author: yuqy
 * @Date: 2021-01-28 23:29:38
 * @LastEditors: yuqy
 * @LastEditTime: 2021-04-19 11:49:04
 * @model: -
 */
// import Vue from 'vue'
const getters = {
  token: state => state.user.token,
  welcome: state => state.user.welcome
}

export default getters